import React from 'react';
import { Helmet } from 'react-helmet';
import { Header } from '../components/Header';
import { Footer } from '../components/Footer';
import '../styles/index.css';

function Index({ data }) {
  return (
    <>
      <Helmet>
        <title>Little Martian</title>
      </Helmet>
      <Header />
      <main>
        <h1>Devlog</h1>
        {data.allMdx.edges
          .filter(post => !post.node.frontmatter.draft && !!post.node.frontmatter.date)
          .map(post => (
            <section>
              <a href={post.node.fields.slug}>
                <h2>{post.node.frontmatter.title}</h2>
              </a>
              <h3>{post.node.frontmatter.date}</h3>
            </section>
          ))}
      </main>
      <Footer />
    </>
  );
}

export const pageQuery = graphql`
  query {
    allMdx(sort: { order: DESC, fields: [frontmatter___date] }) {
      edges {
        node {
          id
          excerpt
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            draft
          }
        }
      }
    }
  }
`

export default Index;